/* eslint-disable react/prop-types */
import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as ArrowDown } from '../../images/icons/arrowDown.svg';

import styles from './CategoryPicker.module.scss';
import loadable from '@loadable/component';

const CategoryPickerDropdown = loadable(() => import('./CategoryPickerDropdown'));
const CategoryPicker = ({ classList = {}, selectedCategoryName, isOpen = false, dropDownCallback = () => null }) => {
  const rootStyle = classNames(styles.categoryPicker);

  const clickToOpen = () => {
    dropDownCallback(!isOpen);
  };

  return (
    <>
      <div
        className={classNames(styles.root, classList.root)}
        onClick={clickToOpen}
        onMouseDown={(e) => {
          if (isOpen) {
            e.stopPropagation();
          }
        }}
      >
        <div className={rootStyle}>
          <div className={styles.overflow}>
            <div className={styles.categoryText} data-testid="categoryDropdown">
              <span>
                {selectedCategoryName ? (
                  selectedCategoryName
                ) : (
                  <FormattedMessage default="Categories" id="categories.all" />
                )}
              </span>
            </div>
            <ArrowDown viewBox="0 0 16 16" className={classNames(styles.arrow, { [styles.arrowFlipped]: isOpen })} />
          </div>
        </div>
      </div>
      {isOpen && (
        <CategoryPickerDropdown
          className={styles.categoryDropdown}
          onClose={() => dropDownCallback(false)}
          isOpen={isOpen}
        />
      )}
    </>
  );
};

export default CategoryPicker;
